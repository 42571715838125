<script>
	import { page } from '$app/state';
	import Button from '$lib/Components/Layout/Button/Button.svelte';
	import Grid from '$lib/Components/Layout/Grid/Grid.svelte';
	import Image from '$lib/Components/Layout/Image/Image.svelte';
	import Section from '$lib/Components/Layout/Section/Section.svelte';
	import Parallax from '$lib/Components/Parallax/Parallax.svelte';
	import Youtube from '$lib/Components/Youtube/Youtube.svelte';
	import Awards from '$lib/PageSections/Awards/Awards.svelte';
	import BuildYourOwnEstimate from '$lib/PageSections/BuildYourOwnEstimate/BuildYourOwnEstimate.svelte';
	import FeaturedArticles from '$lib/PageSections/FeaturedArticles/FeaturedArticles.svelte';
	import GetAFreeEstimate from '$lib/PageSections/GetAFreeEstimate/GetAFreeEstimate.svelte';
	import Quote from '$lib/PageSections/Quote/Quote.svelte';
	import Reviews from '$lib/PageSections/Reviews/Reviews.svelte';

	import youtubeThumbnail from './images/7rmhgQzc47Q.jpg';
	import homepage from './images/HH_snow_family_3.jpg?w=400;600;800;1000;1200;1400;1600';
	import insertCorner from './images/insert-A-w-shadow-cropped.png?w=300';
	import noiseGuy from './images/IW-01-home-page-guy-A.svg';
	import upsetGuy from './images/IW-02-home-page-guy-B.svg';
	import showerGuy from './images/IW-03-home-page-guy-C.svg';
	import commercialImage from './images/IW-04-home-page-commercial-A.jpg?w=300;600';
	import thermometer from './images/thermostat-B-no-shadow.png?w=300';
</script>

<svelte:head>
	<meta
		property="og:image"
		content={`${page?.url?.origin}/static/images/image_for_social_open_graph-1200x630.jpg`}
	/>
	<meta property="og:image:alt" content="woman holding Indow insert" />
	<meta property="og:image:width" content="1200" />
	<meta property="og:image:height" content="630" />
	<meta
		property="twitter:image"
		content={`${page?.url?.origin}/static/images/image_for_social_open_graph-1200x630.jpg`}
	/>
	<meta property="twitter:image:alt" content="woman holding Indow insert" />
</svelte:head>
<Section
	backgroundColor="blue"
	class="full-width !py-0"
	innerClass="max-w-[90ch] !justify-items-start !py-0 full-width"
	loading="eager"
>
	<div
		class="col-start-2 !-col-end-1 grid min-h-[clamp(20rem,66dvh,33dvw)] w-full max-w-full grid-cols-[auto_1fr] flex-col gap-8 overflow-hidden"
	>
		<div class="grid h-full w-full gap-6 self-start bg-[#8cc7d2e6] py-8">
			<div
				class="backdrop-text mb-0 place-self-end pt-4 !text-right text-4xl font-medium hyphens-none !text-white md:text-5xl"
			>
				Save up to<br /> $1,200 on<br /> thermal<br /> window<br /> inserts.
			</div>

			<Button
				class="place-self-end self-start"
				href="/window-insert-tax-savings">LEARN MORE</Button
			>
		</div>
		<div class="relative">
			<Image
				src={homepage}
				alt="Indow insert"
				loading="eager"
				class=" relative mr-0 h-full max-w-none"
			/>
		</div>
	</div>
</Section>

<Awards loading="eager" />
<Section backgroundColor={'white'}>
	<h1 class="text-center">
		Eliminate Your Window Worries With Indow Window Inserts
	</h1>
	<p>
		Whether you have a drafty home, a noisy neighborhood, or a desire to reduce
		your energy consumption and costs, interior window inserts from Indow are a
		clear choice.
	</p>
	<Grid columns={3} rows={4} center class="mb-8 gap-x-8">
		<Grid subgrid vertical center>
			<a href="/solutions/insulating-drafty-windows" class="contents">
				<h3 class="mb-0 p-0 text-base uppercase no-underline">
					Comfort & Savings
				</h3>
				<Image
					class="max-w-80"
					src={upsetGuy}
					alt="illustration of distressed person"
					width="150"
					height="150"
					loading="lazy"
				/>
			</a>
			<p>
				Seal out drafts, save money on energy bills and make your space more
				comfortable. All while maintaining historic and architectural integrity
				to preserve value.
			</p>
			<Button color="yellow" href="/solutions/insulating-drafty-windows"
				>LEARN MORE</Button
			>
		</Grid>

		<Grid subgrid vertical center>
			<a href="/solutions/window-noise-reduction" class="contents">
				<h3 class="mb-0 p-0 text-base uppercase no-underline">
					Noise Reduction
				</h3>
				<Image
					class="max-w-80"
					src={noiseGuy}
					alt="illustration of person suffering with noise"
					width="150"
					height="150"
					loading="lazy"
				/>
			</a>
			<p>
				Block outside noise by adding Indow inserts to your existing window
				panes to create a sound-deadening air pocket.
			</p>
			<Button color="yellow" href="/solutions/window-noise-reduction"
				>LEARN MORE</Button
			>
		</Grid>
		<Grid subgrid vertical center>
			<a href="/solutions/light-uv" class="contents">
				<h3 class="mb-0 p-0 text-base uppercase no-underline">Lights & UV</h3>
				<Image
					class="max-w-80"
					src={showerGuy}
					alt="illustration of person wearing a towel, tie, and shower cap"
					width="150"
					height="150"
					loading="lazy"
				/>
			</a>
			<p>
				Whether you're trying to create privacy in any room, fully block light
				for better sleep, or filter damaging UV rays to protect your cherished
				belongings, we've got a solution for you.
			</p>
			<Button color="yellow" href="/solutions/light-uv">LEARN MORE</Button>
		</Grid>
	</Grid>

	<p>
		We can address drafts, energy costs, and noise at work. Indow commercial
		solutions improve the comfort of your work space and free more of your
		company's budget. Explore our commercial window solutions.
	</p>
	<p>
		No matter what your window issue is, Indow windows compression-fit inserts
		work with your existing window frames, so no need to spend a ton of cash
		replacing your windows. Made with proprietary software, our inserts are
		incredibly accurate in size, can be installed without using mounting
		hardware and have such a low-profile they virtually disappear once
		installed.
	</p>
	<p>
		Contact us anytime for a free estimate on installing window inserts at your
		location!
	</p>
</Section>

<Section backgroundColor={'grey-light'} class="pb-0">
	<h2>See How Indow Inserts Work</h2>
	<p>
		With Indow window inserts you don't need to invest in new windows to attain
		comfort. Indow window inserts fit tightly into your existing window frames,
		blocking drafts and hot summer air. They also significantly reduce outside
		noise and help control light.
	</p>
	<Youtube
		videoId="7rmhgQzc47Q"
		posterImageSrc={youtubeThumbnail}
		title="Introducing A Revolutionary Window Insert | Indow®"
	/>
	<p>
		Don't replace your windows. Choose custom-designed window inserts to make
		them just right.
	</p>
	<div class="mt-4 w-full">
		<Parallax --translateY="80%" --marginTop="0%">
			<div class="m-auto grid w-full items-center">
				<Image
					src={thermometer}
					alt="wall thermostat"
					loading="lazy"
					class="max-w-[min(50%,20rem)]"
				/>
			</div>
		</Parallax>
	</div>
</Section>

<Quote>
	<p>
		"We purchased an Indow insert so we could keep our beautiful original 1939
		leaded glass window. Our goal was only to stop drafts. It not only stopped
		the drafts, but it also decreased street noise. We did not expect this
		benefit, what a pleasant surprise." — Darci M.
	</p>
</Quote>

<Section backgroundColor={'grey'} class="!z-0 pt-0 md:pb-24">
	<div class="mt-[10%] mb-[10%] w-full">
		<Parallax --translateY="0%">
			<Image
				src={insertCorner}
				alt="Indow insert corner piece"
				loading="lazy"
				class="max-w-md"
			/>
		</Parallax>
	</div>
	<h2>Storm Window Inserts That Last</h2>
	<p>
		Our storm window inserts aren't windows. They aren't exterior storms or
		magnetic interior storm windows. Instead, they are custom-designed inserts
		that simply press into the interior of your existing window frames, with no
		hardware or mounting brackets required. This means they make your original
		windows perform like new, transforming them to meet your needs without the
		hefty cost of replacement windows.
	</p>
	<p>
		Durable. Custom-made in the U.S. Easy to install. These are just a few of
		the many benefits our inserts offer. Our acrylic window inserts are edged
		with a soft silicone compression tubing that creates a tight seal. Laser
		measuring ensures they fit precisely and stay securely in place. Enjoy all
		the benefits of new windows, but with a simpler, custom-made solution!
	</p>
	<p>
		<a href="/solutions">Explore our site</a> to learn more about how our window
		inserts will help you overcome your window challenges.
	</p>
</Section>
<Section imageColumn={commercialImage} loading="lazy">
	<h2>Comfort &amp; Quiet in Business: Commercial Solutions</h2>
	<p class="md:max-w-[65ch]">
		Consideration for comfort and sanity shouldn't be suspended when you walk
		into the office, or a guest checks into your hotel. Creature comforts matter
		everywhere. Our Commercial Hub has all the information you need to determine
		if Indow window inserts are the best solution for your business's windows.
	</p>
	<Button href="/commercial-storm-windows">LEARN MORE</Button>
</Section>

<BuildYourOwnEstimate />
<GetAFreeEstimate />
<Reviews />
<FeaturedArticles backgroundColor={'blue'} title="Featured Articles" />

<style>
</style>
